<template>
  <nav class="justify-content-center">
    <ul class="nav nav-pills" role="menubar">
      <template v-for="(menu, i) in $store.getters.MENU_LIST_PARENT('000000')" :key="menu.MENU_ID">
        <li class="nav-item" v-if="isAccessToDotBookAllowed(menu.MENU_NAME)" role="none">
          <router-link
            v-if="menu.MENU_URL.startsWith('/')"
            :to="{ path: menu.MENU_URL }"
            class="nav-link p-0 position-relative"
            :class="{ 'kchf-user': isKCHFUser() }"
            role="menuitem"
            @click="topMenuClick(menu)"
            :ref="`router-${i}`"
            :aria-current="isLinkActive(menu.MENU_URL) ? 'page' : null"
          >
            {{ $t(menu.MENU_NAME) }}
            <!-- TODO: 나중에 Beta 뱃지 사용시 아래 v-if 조건 변경하여 사용 -->
            <span
              v-if="menu.MENU_NAME === 'Dot Book' && false"
              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
              style="font-size: 10px;"
              aria-label="Beta version"
            >
              Beta
            </span>
          </router-link>
          <button
            v-else
            type="button"
            class="nav-link p-0 position-relative"
            :class="{ 'kchf-user': isKCHFUser() }"
            role="menuitem"
            @click="goToCloud(menu.MENU_URL)"
          >
            {{ $t(menu.MENU_NAME) }}
          </button>
        </li>
      </template>
    </ul>
    <Preview v-if="previewShow" ref="preview" :selectedItem="selectedItem" />
  </nav>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { $site, $session, $axios } from "dot-framework";
import global from "global";
import {router} from "@/routes";

const Preview = defineAsyncComponent(() => import ("@/views/drive/Preview.vue"));

export default {
  data() {
    return {
      topMenuId: "",
      selectedItem: null,
      previewShow: false,
    }
  },
  components: {
    Preview,
  },
  methods: {
    // 상단 메뉴 클릭
    topMenuClick(menu) {
      this.topMenuId = menu.MENU_ID;
      // (1) 상위메뉴ID store에 저장
      this.$store.commit("setTopMenuId", this.topMenuId);
      // (2) 첫번째 메뉴 처리
      const topMenuFirst = $site.getTopMenuFirst(menu, this.$store);
      this.$store.commit("setSelectedMenu", topMenuFirst.MENU_ID);
    },
    async goToCloud(groupNo) {
      console.log(groupNo)
      if (!groupNo) {
        console.error("No groupNo provided");
        return;
      }

      router.push({name: "cloud", query: {type: "P", parentGroupNo: groupNo}});
    },
    isLinkActive(path) {
      return this.$route.path === path;
    },
    isAccessToDotBookAllowed(MENU_NAME) {
      return global.isDotUser() || MENU_NAME !== "Dot Book";
    },
    isKCHFUser() {
      return global.isKCHFUser();
    },
  },
}
</script>

<style scoped>
.nav {
  gap: 3.1rem;
}
.nav-link {
  text-decoration: none;
  color: #767474;
  font-size: 1.5rem;
}
.nav-link:hover {
  text-decoration: none;
  opacity: 0.8
}
/* .nav-link.active {
  color: #EA5414;
  background: unset;
} */
.router-link-active {
  color: #EA5414;
  background: unset;
}
.router-link-active.kchf-user {
  color: #183F3E;
}

@media all and (min-width:1024px) and (max-width:1279px) {
  .nav {
    gap: 2rem;
  }
  .nav-link {
    font-size: 1.3rem;
  }
}
@media all and (max-width:1023px) {
  .nav {
    gap: 2rem;
  }
  .nav-link {
    font-size: 1.3rem;
  }
}
</style>
